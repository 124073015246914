const Card = (props) => {
  return (
    <div className="flex flex-col items-center w-1/3 p-4">
      <div className="w-full h-full bg-white rounded-lg flex flex-col items-center justify-center p-4">
        {props.image && <img src={props.image} alt={props.title} className="w-full h-auto object-contain mb-4 p-4" />}
        {props.title && (
          <>
            <p className="font-bold text-[#26394A] text-lg text-center">
              {props.title}
            </p>
            <hr className="self-center border-black rounded-full w-1/2 border-[0.09rem] mt-2"></hr>
          </>
        )}
        {props.contents && (
          <p className="text-sm font-normal mt-4 text-center">
            {props.contents}
          </p>
        )}
      </div>
    </div>
  );
}

export default Card;