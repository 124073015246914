import './style.css';
import image from '../../media/THD.png';
const Footer = () => {

    const navigateToInstagram = () => {
        // Use window.location.href if you want to navigate in the same tab
        // window.location.href = 'https://www.instagram.com/torontohealthdatathon/';

        // Use window.open if you want to open a new tab
        window.open('https://www.instagram.com/torontohealthdatathon/', '_blank');
      };

      const navigateToLinkedIn = () => {
        // Use window.location.href if you want to navigate in the same tab
        // window.location.href = 'https://www.linkedin.com/company/toronto-health-datathon/';
        
        // Use window.open if you want to open a new tab
        window.open('https://www.linkedin.com/company/toronto-health-datathon/', '_blank');
      };

    return (
      <footer id="footer" className="flex flex-row bg-cover">
        <div className="flex flex-row lg:mb-[4rem] small:mb-[2.125rem] small:ml-[1.25rem] lg:ml-[2.4375rem] mb-[1.875rem] ml-[1.875rem] small:mt-[9.5625rem] lg:mt-[14.9375rem] mt-[9.5625rem]">
          <img
            src={image}
            alt="logo"
            className="h-[3.0625rem] w-[3.0625rem] lg:h-[6.3125rem] lg:w-[6.3125rem] small:h-[4.046875rem] small:w-[3.25rem]"
          />
          <div className="w-[4.8125rem] h-[1.875rem] mt-[1.0625rem] mb-[0.125rem] flex flex-col small:w-[5.9375rem] small:h-[2.25rem] small:mt-[2.05rem] small:mb-[0rem] lg:w-[9.8125rem] lg:h-[3.75rem] lg:mt-[2.1875rem] lg:mb-[0.375rem]">
            <p className="font-[Poppins] text-[0.625rem] lg:text-[1.25rem] font-[500] text-white">
              National Health
            </p>
            <p className="font-[Poppins] text-[0.625rem] lg:text-[1.25rem] font-[500] text-white">
              Datathon
            </p>
          </div>
        </div>
        <div
          id="insta"
          className="transition ease-in-out duration-300 hover:-translate-y-1 hover:scale-110 h-[2.0625rem] w-[2.0625rem] lg:h-[2.75rem] small:mt-[11.5625rem] small:static small:ml-[2.125rem] lg:w-[2.75rem] small:mb-[2.1875rem] lg:mb-[4.875rem] lg:ml-[3.3125rem] lg:mt-[17.5rem] cursor-pointer mt-[10.625rem] mb-[1.6875rem] absolute right-[6.3125rem] lg:static lg:right-0"
          onClick={() => navigateToInstagram()}
        ></div>
        <div
          id="linkedin"
          className="transition ease-in-out duration-300 hover:-translate-y-1 hover:scale-110 h-[2.0625rem] w-[2.0625rem] lg:h-[2.75rem] lg:w-[2.75rem] small:mt-[11.5625rem] small:ml-[2.375rem] small:static small:mb-[2.1875rem] lg:mb-[4.875rem] lg:ml-[3.125rem] lg:mt-[17.5rem] cursor-pointer mt-[10.625rem] mb-[1.6875rem] absolute right-[1.875rem] lg:static lg:right-0"
          onClick={() => navigateToLinkedIn()}
        ></div>
      </footer>
    );

};

export default Footer;