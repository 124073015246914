export const DAY_ONE = [

    {
        title: 'Registration',
        startTime: '11:00 AM',
        endTime: '11:30 AM',
        description: 'Please ensure you arrive a little early.',
        speakers: []
    },
    {
        title: 'Opening Remarks & Dataset Overview',
        startTime: '11:30 AM',
        endTime: '12:00 PM',
        description: '',
        speakers: []
    },
    {
        title: 'Lunch',
        startTime: '12:00 PM',
        endTime: '01:00 PM',
        description: '',
        speakers: []
    },
    {
        title: 'Working Session',
        startTime: '01:00 PM',
        endTime: '04:15 PM',
        description: '',
        speakers: []
    },
    {
        title: 'Working with team from other university',
        startTime: '04:15 PM',
        endTime: '05:00 PM',
        description: '',
        speakers: []
    },
]

export const DAY_TWO = [
    {
        title: 'Registration',
        startTime: '11:00 AM',
        endTime: '11:30 AM',
        description: '',
        speakers: []
    },
    {
        title: 'Working Session',
        startTime: '11:30 AM',
        endTime: '12:00 PM',
        description: '',
        speakers: []
    },
    {
        title: 'Lunch',
        startTime: '12:00 PM',
        endTime: '01:00 PM',
        description: '',
        speakers: []
    },
    {
        title: 'Working Session',
        startTime: '01:00 PM',
        endTime: '03:30 PM',
        description: '',
        speakers: []
    },
    {
        title: 'Presentation and judging',
        startTime: '03:30 PM',
        endTime: '04:30 PM',
        description: '',
        speakers: []
    },
    {
        title: 'Prizes and closing remarks',
        startTime: '04:30 PM',
        endTime: '05:00 PM',
        description: '',
        speakers: []
    },
]