import { Link } from 'react-router-dom';
import { Slide, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

import './index.css';

import AboutCard from '../../components/aboutcard';
import GlanceCard from '../../components/glancecard';
import SponsorCard from '../../components/sponsorcards';

const aboutCardContents = [
  "University of British Columbia, Vancouver",
  "University of Toronto, Toronto",
  "Dalhousie University, Halifax",
];
const aboutCardTitles = ["UBC", "UofT", "Dalhousie University"];
const aboutCardImages = [
    "/ubc-logo.png",
    "/tcairemlogo_white.png",
    "/dal_shift_merged.jpg",
];

const properties = {
  prevArrow: (
    <button className="max-[460px]:w-[1.25rem] max-[740px]:w-[2rem] max-[1000px]:w-[3rem] max-[1320px]:w-[4rem] w-[5rem] max-[460px]:h-[1.25rem] max-[740px]:h-[2rem] max-[1000px]:h-[3rem] max-[1320px]:h-[4rem] h-[5rem] 
    bg-[#D9D9D9] rounded-full cursor-default max-[570px]:ml-[0.5rem] max-[1000px]:ml-[1rem] ml-[1.4375rem]">
      <div className="max-[460px]:ml-[0.55rem] max-[740px]:ml-[0.9rem] max-[1000px]:ml-[1.4rem] max-[1320px]:ml-[1.85rem] ml-[2.175rem] 
      max-[460px]:w-1.5 max-[740px]:w-2.5 max-[1000px]:w-4 max-[1320px]:w-6 w-7 max-[460px]:h-1.5 max-[740px]:h-2.5 max-[1000px]:h-4 max-[1320px]:h-6 h-7 
      rotate-45 border-l border-b border-black "></div>
    </button>
  ),
  nextArrow: (
    <button className="max-[460px]:w-[1.25rem] max-[740px]:w-[2rem] max-[1000px]:w-[3rem] max-[1320px]:w-[4rem] w-[5rem] max-[460px]:h-[1.25rem] max-[740px]:h-[2rem] max-[1000px]:h-[3rem] max-[1320px]:h-[4rem] h-[5rem] 
    bg-[#D9D9D9] rounded-full cursor-default max-[570px]:mr-[0.5rem] max-[1000px]:mr-[1rem] mr-[1.4375rem]">
      <div className="max-[460px]:ml-[0.4rem] max-[740px]:ml-[0.55rem] max-[1000px]:ml-[0.7rem] max-[1320px]:ml-[0.75rem] ml-[1rem] 
      max-[460px]:w-1.5 max-[740px]:w-2.5 max-[1000px]:w-4 max-[1320px]:w-6 w-7 max-[460px]:h-1.5 max-[740px]:h-2.5 max-[1000px]:h-4 max-[1320px]:h-6 h-7 
      rotate-45 border-r border-t border-black"></div>
    </button>
  ),
};

const indicators = () => (
  <div className="dot max-[460px]:mt-[-2rem] max-[740px]:mt-[-2.3rem] mt-[-2.7rem] z-10"></div>
);

const sponsors = [
  "google",
  "tcairem",
];

const Landing = () => {
  return (
    <div>
      {/* Hero section */}
      <div id="hero-section" className="flex flex-col mt-[-4.5625rem] min-[820px]:mt-[0]">
        <div className="max-[820px]:mx-[1.875rem] max-[1000px]:mx-[4.875rem] mx-[8.75rem] text-black pb-12 lg:pt-11">
          <div>
            <h1 className="black mt-20 text-[32px] lg:text-[64px]">
              {" "}
              National Health Datathon 2024
            </h1>
          </div>
          <div className="my-8 lg:my-12">
            <h2 className="text-[20px] lg:text-[32px]">November 9-10, 2024</h2>
          </div>
          <div className="flex flex-row flex-wrap justify-between">
          <div
              id="register"
              className="cursor-pointer font-bold transition ease-in-out duration-300 hover:-translate-y-1 hover:scale-110"
              onClick={() =>
                  window.open(
                      "https://ubc.ca1.qualtrics.com/jfe/form/SV_0UqRRjnLXgbDHFk",
                      "_blank"
                  )
              }
          >Register Now
            <div className="flex flex-row" style={{ fontSize: '1.95rem'}}>
            (British Columbia)
            </div>
          </div>
          <div
              id="register"
              className="cursor-pointer font-bold transition ease-in-out duration-300 hover:-translate-y-1 hover:scale-110"
              onClick={() =>
                  window.open(
                      "https://www.eventbrite.com/e/national-health-datathon-2024-toronto-tickets-1003677716067",
                      "_blank"
                  )
              }
          >
            Register Now (Toronto)
          </div>
          <div
              id="register"
              className="cursor-pointer font-bold transition ease-in-out duration-300 hover:-translate-y-1 hover:scale-110"
              onClick={() =>
                  window.open(
                      "https://airtable.com/appcCupd2hOR70F1N/shruvswXb2JLJ4b96",
                      "_blank"
                  )
              }
          >
            Register Now (Nova Scotia)
          </div>
          </div>
        </div>
      </div>
      <br />

      {/* Come Join Us Section */}
      <div className="mt-6 mb-6 max-[820px]:mx-[1.875rem] max-[1000px]:mx-[4.875rem] mx-[8.75rem]">
        <h2 className="mb-4 text-[22px] lg:text-[32px]">Come Join Us!</h2>
        <p className="max-[440px]:text-[0.75rem]">
          The National Health Datathon 2024 is open to students, trainees, researchers, and professionals
          in the fields of medicine, data science, computing, and more! Join teams of participants from universities
          across the country in developing machine learning models to solve relevant problems with real medical data.
        </p>
        <br></br>
        <p className="max-[440px]:text-[0.75rem]">
          Get ready to listen to world-renowned guest speakers, work with
          incredible mentors and peers, as well as participate in fun activities
          throughout the event!
        </p>
      </div>

      {/* Red Background Section */}
      <div>
        <div id="red_top"></div>
        <div className="bg-[#DF4440] mt-[-0.25rem] mb-[-0.25rem] z-10">
          <div className="max-[820px]:mx-[1.875rem] max-[1000px]:mx-[4.875rem] mx-[8.75rem]">
            <p className="font-bold max-[820px]:text-[1.25rem] text-[2.25rem] text-white">
              Thank you to our partner institutions!
            </p>
            <div
                className="flex flex-row flex-wrap justify-center mt-[1.5rem] max-[820px]:mb-[1rem] mb-[4rem]">
              {Array.from({length: 3}, (_, i) => (
                  <AboutCard
                      key={i}
                      image={aboutCardImages[i]}
                  />
              ))}
            </div>
          </div>
          <div
              className="max-[820px]:mx-[1.875rem] max-[1000px]:mx-[4.875rem] mx-[8.75rem] mt-[4rem] max-[820px]:pb-[1rem] pb-[4rem]">
            <p className="font-bold max-[820px]:text-[1.25rem] text-[2.25rem] text-white">
              A Glance at Last Year
            </p>
            <div className="flex-auto mt-[0.9rem]">
              <Fade
                onChange={function noRefCheck() {}}
                onStartChange={function noRefCheck() {}}
                autoplay={true}
                {...properties}
                transitionDuration={500}
                canSwipe={false}
                indicators={indicators}
                cssClass="aspect-[2.25]"
              >
                <GlanceCard category="Winners" />
                <GlanceCard category="Team" />
                <GlanceCard category="Committee" />
              </Fade>
            </div>
          </div>
        </div>
        <div id="red_bottom"></div>
      </div>

      <div
          className="max-[820px]:mx-[1.625rem] max-[1000px]:mx-[4.875rem] mx-[8.75rem] max-[820px]:mt-[-2rem] max-[1100px]:mt-[0rem] mt-[1rem]">
        {/* Dataset Section */}
        <h2 className="font-bold max-[820px]:text-[1.375rem] max-[820px]:mb-[1.25rem] mb-[2rem]">
          What Are Our Datasets?
        </h2>
        {/*<h3 className="text-center bg-gray-100 p-4 rounded-lg text-[15px] lg:text-[24px] mt-4">*/}
        {/*  Stay tuned for more information!*/}
        {/*</h3>*/}
          <a
            className="max-[540px]:text-[1.125rem] text-[1.25rem] font-bold text-[#E97D1B] hover:underline"
            href=""
            target="_blank"
            rel="noopener noreferrer"
          >
            Comprehensive Sleep Laboratory Data
          </a>
          <p className="max-[820px]:mt-[0.5rem] mt-[1.25rem] max-[540px]:text-[1rem] max-[820px]:mb-[1.75rem] mb-[2.5rem]">
            Data from Sunnybrook Sleep Laboratory that includes de-identified raw overnight signals,
            scored sleep metrics, sleep and health questionnaires, and medications/medical history.
          </p>
          <a
            className="max-[540px]:text-[1.125rem] text-[1.25rem] font-bold text-[#E97D1B] hover:underline"
            href="https://healthdatanexus.ai/content/cspine/1.0.0"
            target="_blank"
            rel="noopener noreferrer"
          >
            Fracture Detection on Cervical Spine CT
          </a>
          <p className="max-[820px]:mt-[0.5rem] mt-[1.25rem] max-[540px]:text-[1rem] max-[820px]:mb-[1.75rem] mb-[2.5rem]">
            This dataset is composed of merged CT scans of the cervical spine in Digital Imaging and Communications
            in Medicine (DICOM) format. Images are provided in the axial axis and bone window kernel.
          </p>
          <a
            className="max-[540px]:text-[1.125rem] text-[1.25rem] font-bold text-[#E97D1B] hover:underline"
            href="https://healthdatanexus.ai/content/smh-gim/1.0.0"
            target="_blank"
            rel="noopener noreferrer"
          >
            GIM, a Dataset for Predicting Patient Deterioration in the General
            Internal Medicine Ward
          </a>
          <p className="max-[820px]:mt-[0.5rem] mt-[1.25rem] max-[540px]:text-[1rem]">
            The General Internal Medicine (GIM) dataset is comprised of de-identified health related data associated
            with over 22,000 patient encounters for 14,000 unique patients who were admitted under
            the GIM service at St. Michael’s Hospital.
          </p>

        {/*  /!* Sponsorship Section *!/*/}
        {/*  <h2 className="font-bold max-[820px]:text-[1.375rem] mt-[5rem]">*/}
        {/*    Who Are Our Sponsors?*/}
        {/*  </h2>*/}
        {/*  <p className="max-[820px]:text-[1.25rem] mt-[2rem] max-[540px]:text-[1rem]">*/}
        {/*    Thank you to this year’s sponsors! Check back later for future*/}
        {/*    updates.*/}
        {/*  </p>*/}
        {/*  <div className="flex flex-row flex-wrap justify-evenly mt-[2rem] lg:mt-[5rem] mb-[-1rem] gap-[0.5rem] lg:gap-[1rem]">*/}
        {/*    {sponsors.map((sponsor) => {*/}
        {/*      return <SponsorCard sponsor={sponsor} />;*/}
        {/*    })}*/}
        {/*  </div>*/}
      </div>
    </div>
  );
};

export default Landing;